.titles {
	position: relative;
	width: 100vw;
	min-height: 70vh;

	&::before {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: -1;
		opacity: 0.2;

		background-image: url("../images/bg-image-4.png");
		background-size: cover;
		background-position-x: right;
	}

	&__container {
		display: flex;
		flex-direction: column;
		gap: 20px;
		margin: 0 50px;
		padding: 30px 0;
		max-height: 600px;
	}

	// .titles__general
	&__general {
		display: flex;
		justify-content: space-between;
		gap: 30px;
		@media (max-width: 550px) {
			flex-direction: column;
			justify-content: center;
		}
	}

	// .titles__text
	&__text {
		font-size: 28px;
		font-family: var(--font-sans-serif);
		color: var(--main-color);
		white-space: nowrap;
	}

	// .titles__search
	&__search {
		border: none;
		outline: none;
		background-color: #ecebf2;
		opacity: 0.7;
		border-radius: 30px;
		padding: 10px 10px;
		font-size: 18px;
		font-family: var(--font-sans-serif-2);
	}

	// .titles__list
	&__list {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		gap: 30px;
		overflow: auto;
		max-height: 370px;
	}

	// .titles__item
	&__item {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 15px;
	}
	// .titles__item-image
	&__item-image {
		object-fit: contain;
		max-width: 300px;
		height: 300px;
	}
	&__item-text {
		background-color: var(--main-color);
		width: 100%;
		font-family: var(--font-sans-serif);
		font-size: 18px;
		text-align: center;
		padding: 10px 15px;
		border-radius: 10px;
	}
}

.title {
	position: relative;
	margin-bottom: 30px;

	&__container {
		display: flex;
		flex-direction: column;
		gap: 30px;
		margin: 0 clamp(0.625rem, -1.356rem + 8.45vw, 6.25rem);
	}

	&__chapters-block {
		position: relative;
		display: flex;
		flex-direction: column;
		gap: 10px;
	}

	&__chapters-list {
		position: relative;
		display: flex;
		flex-direction: column;
		gap: 10px;
		max-height: 400px;
		overflow: auto;
	}

	&__chapters-heading {
		font-family: var(--font-sans-serif);
		font-weight: 700;
	}

	&__chapters-link {
		padding: 10px;
		width: 100%;
		background-color: var(--soft-main-color);
		color: #000;
		font-family: var(--font-sans-serif);
		font-size: 20px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__chapters-info {
		overflow: visible;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px;
		width: 100%;
		background-color: var(--main-color);
	}

	&__chapters-search {
		position: relative;
		border: none;
		outline: none;
		font-family: var(--font-sans-serif);
		font-size: 18px;
		padding: 5px 15px;
		border-radius: 10px;
		min-width: 250px;
		display: flex;
		align-items: center;
		&-icon {
			position: absolute;
			right: 15px;
			//cursor: pointer;
		}
	}

	&__chapters-icon {
		scale: 0.7;
	}

	// .title__back
	&__back {
		cursor: pointer;
		align-self: flex-start;
		justify-self: flex-end;
		width: 64px;
	}

	// .title__info
	&__info {
		display: flex;
		gap: 30px;
		justify-content: space-between;
		@media (max-width: 900px) {
			align-items: center;
			justify-content: center;
		}
	}

	// .title__image
	&__image {
		display: flex;
		width: fit-content;
		gap: 30px;
		@media (max-width: 900px) {
			flex-direction: column;
			align-items: center;
		}
	}

	// .title__cover
	&__cover {
		width: 320px;
	}

	// .title__info-list
	&__info-list {
		display: flex;
		flex-direction: column;
		gap: 15px;
	}

	// .title__info-text
	&__info-text {
		font-family: var(--font-sans-serif);
		font-weight: 700;
		font-size: 20px;
		white-space: nowrap;

		&:last-child {
			margin-top: auto;
			width: fit-content;
			padding: 10px 25px;
			background-color: var(--main-color);
			border-radius: 10px;
		}
		span {
			font-family: inherit;
			font-weight: inherit;
			font-size: inherit;
			color: var(--main-color);
		}
	}
}
