.profile {
	&__banner-image {
		position: absolute;
		top: 0;
		z-index: -10;
		opacity: 0.2;

		height: 55%;
		width: 100%;
		object-fit: cover;
	}

	&__container {
		display: flex;
		flex-direction: column;
		gap: 30px;
	}

	&__status {
		display: flex;
		flex-direction: column;
		gap: 10px;
		width: 200px;
	}
	&__status-text {
		font-family: var(--font-serif);
		font-weight: 600;
		font-size: 14px;
		color: var(--main-color);
		text-wrap: none;
	}

	// .profile__heading
	&__heading {
		font-family: var(--font-sans-serif);
		font-weight: 700;
		font-size: 48px;
		letter-spacing: 2px;
	}

	// .profile__info
	&__info {
		display: flex;
		gap: 15px;
		justify-content: space-between;
		align-items: flex-start;
	}

	&__user {
		display: flex;
		gap: 25px;
		align-items: flex-start;
	}

	// .profile__user-image
	&__user-image {
		width: 200px;
		height: 200px;
	}

	// .profile__info-list
	&__info-list {
		height: 200px;
		display: flex;
		flex-direction: column;
		gap: 10px;
		align-items: flex-start;
	}

	// .profile__item
	&__item {
		font-family: var(--font-sans-serif);
		font-weight: 700;
		font-size: 20px;
	}

	// .profile__actions
	&__actions {
		display: flex;
		align-items: center;
		gap: 20px;
	}

	// .profile__action
	&__action {
		width: 32px;
		height: 32px;
		object-fit: contain;
	}
}
