.header {
	position: relative;
	display: flex;
	gap: 30px;
	align-items: center;
	justify-content: space-between;
	margin: 20px 20px 0 20px;

	&__logo {
		display: flex;
		align-items: center;
		gap: 10px;
	}
	// .header__logo-image
	&__logo-image {
		width: 92px;
		height: 92px;
	}

	// .header__logo-text
	&__logo-text {
		font-size: 32px;
		font-weight: 400;
		cursor: pointer;
		@media (max-width: 475px) {
			display: none;
		}
	}

	// .header__navigation
	&__navigation {
		display: flex;
		gap: 30px;
		@media (max-width: 730px) {
			display: none;
		}
	}

	&__navigation-mobile {
		display: none;
	}

	&__navigation-link {
		font-size: 22px;
		text-transform: capitalize;
	}

	&__right {
		display: flex;
		align-items: center;
		gap: 20px;
	}

	&__user {
	}

	&__user-icon {
		width: 48px;
	}

	// .header__social
	&__social {
		display: flex;
		gap: 10px;
	}

	// .header__social__link
	&__social__link {
	}

	// .header__social-icon
	&__social-icon {
		width: 32px;
	}
}

.header__navigation-mobile {
	@media (max-width: 730px) {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 20px;
		margin: 20px 0;
	}
}
