.auth {
	position: relative;
	width: 100vw;
	height: 100vh;
	display: flex;

	&__right,
	&__left {
		position: relative;
		width: 50%;
		height: 100%;

		&::before {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: -1;

			background-repeat: no-repeat;
			background-position-y: center;
			background-size: cover;
		}
	}

	// .auth__left
	&__left {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 20px;
		background-color: rgba(#000000, 0.4);

		@media (max-width: 880px) {
			width: 100%;
		}

		&::before {
			background-image: url("../images/bg-image-2.webp");
			opacity: 0.3;
		}
	}

	&__right {
		@media (max-width: 880px) {
			width: 0;
		}
		&::before {
			opacity: 0.7;
			background-position-y: top;
			background-image: url("../images/bg-image-3.jpg");
		}
	}

	// .auth__logo
	&__logo {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 10px;
	}

	// .auth__logo-img
	&__logo-img {
		width: 92px;
		height: 92px;
	}

	// .auth__logo-text
	&__logo-text {
		font-size: 32px;
		cursor: pointer;
	}

	// .auth__form
	&__form {
		display: flex;
		flex-direction: column;
		gap: 25px;
		min-width: 350px;
	}

	// .auth__form-text
	&__form-text {
		align-self: center;
		font-size: 30px;
	}

	// .auth__input-field
	&__input-field {
		position: relative;
		display: flex;
		align-items: center;
		width: 100%;
	}

	// .auth__form-input
	&__form-input {
		position: relative;
		width: 100%;
		border: none;
		outline: none;
		border-radius: 10px;
		padding: 15px 10px;
		font-size: 18px;
		font-family: var(--font-sans-serif);
	}

	&__input-field-image {
		position: absolute;
		right: 10px;
		cursor: pointer;
	}

	// .auth__radiobutton
	&__radio {
		cursor: pointer;
	}

	&__radio-text {
		font-size: 18px;
		font-family: var(--font-sans-serif);
	}

	// .auth__form-button
	&__form-button {
		cursor: pointer;
		font-size: 20px;
		background-color: var(--main-color);
		border-radius: 10px;
		border: none;
		outline: none;
		padding: 10px;
		font-family: var(--font-sans-serif);
	}

	&__form-actions {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}
	&__form-action {
		border: none;
		outline: none;
		background-color: transparent;
		color: var(--main-color);
		font-size: 18px;
		cursor: pointer;
	}
}
