.chapter {
	position: relative;
	width: 100vw;

	&__container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: clamp(21.875rem, 11.972rem + 42.25vw, 50rem);
		gap: 15px;
		margin: 30px 0;
	}

	// .chapter__image
	&__image {
		width: 100%;
		&::selection {
			background-color: transparent;
		}
	}

	&__selection {
		position: fixed;
		left: 10px;
		bottom: 10px;
		padding: 5px 10px;
		border: none;
		outline: none;
		background-color: var(--main-color);
		color: #ffffff;
		appearance: none;
	}

	&__option {
		font-family: var(--font-serif);
		font-size: 14px;
	}

	&__navigation {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 15px;
		width: 100%;
	}

	// .chapter__button
	&__button {
		padding: 10px 15px;
		border-radius: 10px;
		background-color: var(--main-color);
		width: 100px;
		text-align: center;
	}
}
