@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Tinos:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
	--bg-color: #0e0e0e;
	--bg-selection: #9d9e98;
	--main-color: #27a776;
	--soft-main-color: #98c7b6;

	--font-serif: "Tinos", serif;
	--font-sans-serif: "Lato", sans-serif;
	--font-sans-serif-2: "Montserrat", sans-serif;

	--animation-slow: 0.7s;
	--animation-normal: 0.5s;
	--animation-fast: 0.3s;
}
::selection {
	background-color: var(--bg-selection);
	color: #ffffff;
	//color: var(--main-color);
}
::-webkit-scrollbar {
	width: 0;
}
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: var(--font-serif);
	scrollbar-width: none;
}
[class*="container"] {
	position: relative;
	margin: auto !important;
	width: clamp(21.563rem, 2.967rem + 79.34vw, 74.375rem);
}
html {
	width: 100vw;
	scroll-behavior: smooth;
	background-color: var(--bg-color);
}
#root {
	display: flex;
	flex-direction: column;
	gap: 20px;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
label,
li {
	cursor: default;
	color: #ffffff;
}
a {
	text-decoration: none;
	color: #ffffff;
}
ul,
ol {
	list-style: none;
}
//img {
//	-moz-user-select: none;
//	-webkit-user-select: none;
//	-ms-user-select: none;
//	user-select: none;
//	-webkit-user-drag: none;
//	user-drag: none;
//	-webkit-touch-callout: none;
//}
