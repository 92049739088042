.hero {
	//position: relative;

	// .hero__burger
	&__burger {
		position: absolute;
		top: 50%;
		left: 5%;
		transform: translate(0, -50%);
		width: 30px;
		height: 18px;
		cursor: pointer;
		z-index: 110;
		transition: all var(--animation-slow) ease;

		span {
			position: absolute;
			width: 100%;
			height: 2px;
			background-color: #ffffff;
			top: calc(50% - 1px);
			transform: translate(0, -50%);
			transition: all var(--animation-normal) ease;
			&:first-child {
				top: 0;
			}
			&:last-child {
				top: auto;
				bottom: 0;
			}
		}

		&.active-burger {
			//transform: translate(0, -200%);

			span {
				background-color: rgba(#27a776, 0.9);
				transform: rotate(135deg);
				&:first-child {
					transform: rotate(135deg);
					top: calc(50% - 1px);
				}
				&:last-child {
					transform: rotate(45deg);
					bottom: calc(50% - 1px);
				}
			}
		}
	}

	// .hero__content
	&__content {
		position: relative;
		display: flex;
		flex-direction: column;
		gap: 15px;
		align-items: center;
		justify-content: center;
		min-height: 100px;
	}

	// .hero__title
	&__title {
		font-size: clamp(1.25rem, 0.766rem + 2.07vw, 2.625rem);
	}

	// .hero__description
	&__description {
		font-size: clamp(1rem, 0.956rem + 0.19vw, 1.125rem);
	}

	// .hero__deco
	&__deco {
		display: flex;
		align-items: center;
		gap: 20px;
	}

	// .hero__deco-line
	&__deco-line {
		width: 80px;
		height: 2px;
		background-color: var(--main-color);
		@media (max-width: 820px) {
			display: none;
		}
	}

	// .hero__sidebar
	&__sidebar {
		position: fixed;
		width: 30vw;
		height: 100vh;
		top: 0;
		left: -100%;
		background-color: black;
		z-index: 100;
		min-width: 375px;
		transition: all var(--animation-slow) ease;

		&::before {
			content: "";
			position: absolute;

			width: 100%;
			height: 100%;
			opacity: 0.2;
			background-image: url("../images/bg-image.jpg");
			background-position: left;
			//background-attachment: fixed;
			background-repeat: no-repeat;
		}

		&.active-sidebar {
			left: 0;
		}
	}

	// .hero__sidebar-list
	&__sidebar-list {
		position: absolute;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 15px;
		width: 100%;
		height: 100%;
	}

	// .hero__sidebar-list-item
	&__sidebar-list-item {
		font-size: 22px;
		color: var(--main-color);
	}
}
