.settings {
	&::before {
		content: "";
		position: absolute;
		background-image: url("../images/bg-image.jpg");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		width: 100vw;
		height: 100vh;
		background-color: #000;
		opacity: 0.2;
		z-index: -10;
		top: 0;
		overflow: hidden;
	}

	&__container {
		position: relative;
		display: flex;
		gap: 40px;
		align-items: flex-start;
	}
	&__setting-list {
		//position: fixed;
		display: flex;
		flex-direction: column;
		gap: 20px;
		padding: 20px 0;
		height: 400px;
	}
	&__setting-item {
		font-family: var(--font-sans-serif-2);
		font-size: 24px;
		cursor: pointer;

		&:last-child {
			margin-top: auto;
			background-color: var(--main-color);
			border: none;
			outline: none;
			border-radius: 20px;
			padding: 5px 10px;
		}
	}
	&__settings {
		position: relative;
		display: flex;
		flex-direction: column;
		scroll-behavior: smooth;
		gap: 20px;
		padding: 20px;
		z-index: 10;
		width: 100%;
		max-height: 400px;
		border-radius: 20px;
		overflow: auto;
		background-color: rgba(#27a776, 0.4);
	}
	&__settings-blocks {
		display: flex;
		flex-direction: column;
		gap: 10px;
		margin-top: 40px;
		&:first-child {
			margin-top: 0;
		}
	}
	&__settings-block {
		display: flex;
		flex-direction: column;
		gap: 5px;
	}
	&__settings-title {
		font-size: 24px;
		font-family: var(--font-sans-serif);
		margin-bottom: 10px;
	}
	&__settings-parameter-text {
		font-size: 18px;
		font-family: var(--font-sans-serif);
	}
	&__settings-parameter-button,
	&__settings-parameter-input {
		padding: 10px;
		border: none;
		outline: none;
		background-color: transparent;
	}
	&__settings-parameter-input {
		border-bottom: 2px solid #0e0e0e;
		color: #ffffff;
		font-size: 18px;
		font-family: var(--font-sans-serif-2);
	}
	&__settings-parameter-button {
		cursor: pointer;
		background-color: var(--main-color);
		border-radius: 10px;
		width: fit-content;
		padding: 10px 20px;

		&:disabled {
			&:hover {
				background-color: var(--main-color);
			}
			cursor: default;
		}
		&:hover {
			background-color: var(--soft-main-color);
		}
	}
}
